import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { useState, useEffect, useContext } from "react";
import { myContext } from "./MyContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getCookie } from "./Cookie";
import { path } from "./path";
import { bankinfo } from "./bankinfo";
const cookie = getCookie();
const CustomerSupport = () => {
  return (
    <div>
      <Header />

      <div id="appCapsule">
        <div className="section mb-5 p-2">
          <div className="body-support">
            <div class="container">
              <h1>Customer Support</h1>
              <div class="contact-info">
                <p>If you need assistance, feel free to contact us:</p>
                <p class="phone">
                  Toll-Free Number: <a href="tel:18005721577">1800-5721-577</a>
                </p>
                <p class="phone">
                  WhatsApp Number:{" "}
                  <a href="https://wa.me/919193001500">9193001500</a>
                </p>
                <p class="email">
                  Email: <a href="mailto:info@grewbig.com">info@grewbig.com</a>
                </p>
                <p>Our customer support team is available:</p>
                <ul>
                  <li>Monday - Friday: 9:00 AM - 6:00 PM (IST)</li>
                  <li>Saturday: 10:00 AM - 4:00 PM (IST)</li>
                  <li>Sunday: Closed</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* App Bottom Menu */}
      <Footer />
      {/* * App Bottom Menu */}

      {/* App Sidebar */}
      <Sidebar />
      {/* * App Sidebar */}
    </div>
  );
};

export default CustomerSupport;
